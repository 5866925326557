import {
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILED,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_SUCCESS,
  UPDATE_WALLET_BALANCE_FAILED,
  CLEAR_PAYMENT_MESSAGES,
  UPDATE_PAYMENT_METHOD
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import { firebase } from '../config/configureFirebase';
import store from '../store/store';
import { onValue, get, update, push } from "firebase/database";

export const fetchPaymentMethods = () => async (dispatch) => {
  const { config, paymentSettingsRef } = firebase;
  const usertype = store.getState().auth.profile.usertype;

  dispatch({ type: FETCH_PAYMENT_METHODS, payload: null });

  try {
      if (usertype === 'admin') {
          // Fetch payment methods for admin
          onValue(paymentSettingsRef, (snapshot) => {
              const data = snapshot.val();
              if (data) {
                  dispatch({ type: FETCH_PAYMENT_METHODS_SUCCESS, payload: data });
              } else {
                  dispatch({
                      type: FETCH_PAYMENT_METHODS_FAILED,
                      payload: store.getState().languagedata.defaultLanguage.no_provider_found,
                  });
              }
          });
      } else {
          // Fetch payment methods for non-admin users
          const settings = store.getState().settingsdata.settings;
          let host = (window && window.location && settings.CompanyWebsite === window.location.origin)
              ? window.location.origin
              : `https://${config.projectId}.web.app`;

          const response = await fetch(`${host}/get_providers`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' }
          });

          const responseJson = await response.json();
          if (responseJson.length > 0) {
              dispatch({ type: FETCH_PAYMENT_METHODS_SUCCESS, payload: responseJson });
          } else {
              dispatch({
                  type: FETCH_PAYMENT_METHODS_FAILED,
                  payload: store.getState().languagedata.defaultLanguage.no_provider_found,
              });
          }
      }
  } catch (error) {
      dispatch({
          type: FETCH_PAYMENT_METHODS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.provider_fetch_error + ": " + error.toString(),
      });
  }
};

export const fetchVNPayPaymentUrl = async (payData) => {
  const { config } = firebase;
  const settings = store.getState().settingsdata.settings;
  let host = (window && window.location && settings.CompanyWebsite === window.location.origin)
      ? window.location.origin
      : `https://${config.projectId}.web.app`;

  const url = `https://us-central1-alogotaxi.cloudfunctions.net/vnpayreal-generate_payment_url`;

  try {
      const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payData),
      });

      const responseJson = await response.json();
      return responseJson.url;
  } catch (error) {
      console.error("Error fetching VNPay payment URL: ", error);
  }
};

export const fetchNPayPaymentUrl = async (payData) => {
  const url = `https://us-central1-alogotaxi.cloudfunctions.net/npay-generate_payment_url`;

  try {
      const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payData),
      });

      const responseJson = await response.json();
      return responseJson.url;
  } catch (error) {
      console.error("Error fetching NPay payment URL: ", error);
  }
};

export const editPaymentMethods = (data) => async (dispatch) => {
  const { paymentSettingsRef } = firebase;

  dispatch({ type: UPDATE_PAYMENT_METHOD, payload: data });

  try {
      await update(paymentSettingsRef, data);
  } catch (error) {
      console.error("Error updating payment methods: ", error);
  }
};

export const clearMessage = () => (dispatch) => {
  dispatch({ type: CLEAR_PAYMENT_MESSAGES, payload: null });
};

export const addToWallet = (uid, amount) => async (dispatch) => {
  const { walletHistoryRef, singleUserRef, settingsRef } = firebase;

  dispatch({ type: UPDATE_WALLET_BALANCE, payload: null });

  try {
      const settingsdata = await get(settingsRef);
      const settings = settingsdata.val();

      onValue(singleUserRef(uid), async (snapshot) => {
          if (snapshot.val()) {
              let walletBalance = parseFloat(snapshot.val().walletBalance);
              const pushToken = snapshot.val().pushToken;
              walletBalance = parseFloat((walletBalance + parseFloat(amount)).toFixed(settings.decimal));

              const details = {
                  type: 'Credit',
                  amount: parseFloat(amount),
                  date: new Date().getTime(),
                  txRef: 'AdminCredit',
              };

              await update(singleUserRef(uid), { walletBalance });
              await push(walletHistoryRef(uid), details);

              dispatch({ type: UPDATE_WALLET_BALANCE_SUCCESS, payload: null });

              if (pushToken) {
                  RequestPushMsg(pushToken, {
                      title: store.getState().languagedata.defaultLanguage.notification_title,
                      msg: store.getState().languagedata.defaultLanguage.wallet_updated,
                      screen: 'Wallet',
                  });
              }
          }
      }, { onlyOnce: true });
  } catch (error) {
      dispatch({
          type: UPDATE_WALLET_BALANCE_FAILED,
          payload: error.code + ": " + error.message,
      });
  }
};

export const withdrawBalance = (profile, amount) => async (dispatch) => {
  const { withdrawRef } = firebase;

  dispatch({ type: UPDATE_WALLET_BALANCE, payload: null });

  try {
      await push(withdrawRef, {
          uid: profile.uid,
          name: `${profile.firstName} ${profile.lastName}`,
          amount: parseFloat(amount),
          date: new Date().getTime(),
          bankName: profile.bankName || '',
          bankCode: profile.bankCode || '',
          bankAccount: profile.bankAccount || '',
          processed: false,
      });
  } catch (error) {
      console.error("Error processing withdrawal: ", error);
  }
};
